// TODO: Break this up more
import React, { useState, useEffect, useRef } from "react";
import { m } from "framer-motion";

import { Image } from "@atoms";
import filterActions from "@utils/filterActions";
import shuffle from "@utils/shuffle";
import brand from "@utils/brand";
import classNames from "classnames";
import loadable from "@loadable/component";

const ActionThankYou = loadable(() => import("@organisms/ActionThankYou"));
const ActionSteps = loadable(() => import("@organisms/ActionSteps"));

const Action = ({
  // way too many params
  heading,
  allActions,
  copy,
  slug,
  readMore,
  readMoreCta,
  shareHeading,
  shareCopy,
  formid,
  activistCodes,
  nextActions,
  backgroundImage,
  thankYouBackgroundImage,
  videoUrl,
  hasCodes,
  meta,
  actionState,
  issue,
  actionDispatch,
  shareUrlOverride,
  url,
  completedHeading,
  alternateAction,
  alternateActionCta,
  supporterCta,
  impactCta,
  thankYouHeading,
  completedCopy,
  thankYouCopy,
  actionType,
  shareCta,
  cta,
  ea,
  levels,
  thankYouFade,
  layout,
  placeholder,
  dispatch,
  amounts,
  signOn,
  supporterSection,
  matterSection,
}) => {
  // refs
  const actionContainer = useRef();
  const thankYouImage = useRef();
  const form = useRef();
  const { lgca, lakota, romero } = brand;

  const [showReadMore, setShowReadMore] = useState(false);

  const bgParams = {
    lakota: {
      exp: -3,
      con: 50,
    },
    romero: {
      exp: -3,
      sat: -50,
    },
    lgca: {
      exp: -25,
      con: 50,
      duotone: "2595DB,FF2400",
      "duotone-alpha": 15,
    },
  };

  /*

    Misc Functions

    */

  /*

    Upcoming Actions

    */

  // upcoming actions

  const actions = shuffle(
    allActions.filter(
      action => action.includeInNextActions && action.slug !== slug
    )
  );

  // actions to show after this action is completed unless there are levels
  const upNext = filterActions(
    [
      ...nextActions,
      ...actions.filter(
        action =>
          !nextActions.map(nextAction => nextAction.slug).includes(action.slug)
      ),
    ],
    ea.activistCodes || []
  ).slice(0, 3);

  /*

  Levels

  */

  // show action submission status
  useEffect(() => {
    if (actionState.status === "submitted") {
      dispatch({ type: "showNav" });
      window.scroll({ top: 0, behavior: "smooth" });
    } else if (actionState.status === "reset") {
      if (!signOn) {
        dispatch({ type: "hideNav" });
      }
    } else if (actionState.status === "completed") {
      dispatch({ type: "showNav" });
    } else if (!signOn) {
      dispatch({ type: "hideNav" });
    }
    return () => dispatch({ type: "showNav" });
  }, [actionState]);

  const actionContainerVariants = {
    unsubmitted: { x: 0 },
    submitted: { x: "-50%" },
    reset: { x: 0 },
    completed: { x: "-50%" },
  };
  const actionStepVariants = {
    unsubmitted: { height: "auto" },
    submitted: { height: 0 },
    reset: { height: "auto" },
    completed: { height: 0 },
  };
  const thankYouStepVariants = {
    unsubmitted: { height: 0 },
    submitted: { height: "auto" },
    reset: { height: 0 },
    completed: { height: "auto" },
  };
  const completeAgainVariants = {
    unsubmitted: { display: "none" },
    submitted: { display: "none" },
    reset: { display: "none" },
    completed: { display: "block" },
  };
  const thankYouImageVariants = {
    unsubmitted: { opacity: 0 },
    submitted: { opacity: 1 },
    reset: { opacity: 0 },
    completed: { opacity: 1 },
  };

  return (
    // container
    <div
      className={classNames(
        "bg relative min-h-1/2-screen  w-full flex-grow overflow-hidden pt-32 lg:pt-48",
        {
          "bg-cream": !levels && (lakota || signOn || romero),
          "bg-red": !levels && lgca && !signOn,
          "bg-black": levels,
        }
      )}
    >
      {/* main background image */}
      <div
        className={classNames(
          "absolute left-0 right-0 top-0 z-0 h-[450px] w-full md:h-[800px] lg:h-[900px] xl:h-[1080px]",
          {
            "bottom-0 bg-black": ((levels && !romero) || lgca) && !signOn,
            "bottom-0 bg-white": signOn,
            "bottom-0 bg-blue-darker": levels && romero,
            "bg-brown": !levels && lakota,
            "bg-blue-darker": !levels && romero,
          }
        )}
      >
        <div
          className={classNames("absolute inset-0", {
            "bg-blue": romero,
            "bg-brown": lakota,
            "bg-orange": lgca,
          })}
          style={levels ? { maxHeight: "100vh" } : null}
        >
          {romero && levels && (
            <div className="absolute inset-0 z-10 bg-gradient-to-b from-blue-darker via-blue-darker/25 to-blue-darker" />
          )}
          {!romero && levels && (
            <div className="absolute inset-0 z-10 bg-gradient-to-b from-black via-black/25 to-black" />
          )}
          {!levels && !lgca && (
            <div className="absolute inset-0 z-10 bg-gradient-to-b from-black/50 via-transparent to-cream" />
          )}
          {!levels && !signOn && lgca && (
            <div className="absolute inset-0 z-10 bg-gradient-to-b from-blue/50 via-blue/10 via-60% to-red" />
          )}
          {signOn && lgca && (
            <div className="absolute inset-0 z-10 bg-gradient-to-b from-white via-white/25 to-offwhite" />
          )}
          <div className="absolute left-0 right-0 top-0 z-0 h-full w-screen opacity-50">
            {backgroundImage && (
              <Image
                image={backgroundImage}
                placeholder={!signOn}
                ixParams={
                  signOn
                    ? { exp: 1, bri: 25, shadow: 25 }
                    : bgParams[brand.brand]
                }
                fill
              />
            )}
          </div>
        </div>
      </div>

      {/* thank you background image */}

      <m.div
        ref={thankYouImage}
        variants={thankYouImageVariants}
        animate={thankYouImageVariants[actionState.status]}
        className={classNames("absolute inset-0 z-0 w-full opacity-0", {
          "bg-brown": lakota,
          "bg-offwhite": lgca,
          "bg-blue-darker": romero,
        })}
      >
        <div
          className="absolute left-0 right-0 top-0 h-full"
          style={{ maxHeight: lgca ? "100vh" : "800px" }}
        >
          <div
            className={classNames(
              "absolute left-0 right-0 top-0 z-10 h-full rotate-180 transform bg-gradient-to-b from-transparent opacity-70",
              {
                "to-black": !romero,
                "to-blue-darker": romero,
              }
            )}
          />
          <div
            className={classNames(
              `absolute bottom-0 left-0 right-0 h-56 ${thankYouFade} z-20 bg-gradient-to-b from-transparent`,
              {
                "to-brown": lakota,
                "to-offwhite": lgca,
                "to-blue-darker": romero,
              }
            )}
          />
          <div className="absolute inset-0 z-0">
            {thankYouBackgroundImage && (
              <Image image={thankYouBackgroundImage} fill />
            )}
            {!thankYouBackgroundImage && backgroundImage && (
              <Image
                image={backgroundImage}
                ixParams={
                  signOn
                    ? { exp: 1, bri: 25, shadow: 25 }
                    : bgParams[brand.brand]
                }
                fill
              />
            )}
          </div>
        </div>
      </m.div>
      {/* container */}
      <m.div
        ref={actionContainer}
        variants={actionContainerVariants}
        animate={actionContainerVariants[actionState.status]}
        className="relative z-10 flex"
        style={{
          width: "200%",
        }}
      >
        {/*
          
          Action 
          
          */}
        <ActionSteps
          actionStepVariants={actionStepVariants}
          layout={layout}
          lgca={lgca}
          heading={heading}
          actionState={actionState}
          levels={levels}
          issue={issue}
          formid={formid}
          activistCodes={activistCodes}
          actionDispatch={actionDispatch}
          actionType={actionType}
          videoUrl={videoUrl}
          meta={meta}
          alternateAction={alternateAction}
          alternateActionCta={alternateActionCta}
          supporterCta={supporterCta}
          impactCta={impactCta}
          cta={cta}
          placeholder={placeholder}
          supporterSection={supporterSection}
          matterSection={matterSection}
          signOn={signOn}
          url={url}
          lakota={lakota}
          copy={copy}
          showReadMore={showReadMore}
          readMore={readMore}
          romero={romero}
          form={form}
          setShowReadMore={setShowReadMore}
          readMoreCta={readMoreCta}
        />
        {/*
          
          Thank You State
          
          */}
        <ActionThankYou
          thankYouStepVariants={thankYouStepVariants}
          actionState={actionState}
          actionDispatch={actionDispatch}
          issue={issue}
          shareCta={shareCta}
          shareUrlOverride={shareUrlOverride}
          formid={formid}
          url={url}
          completeAgainVariants={completeAgainVariants}
          completedHeading={completedHeading}
          thankYouHeading={thankYouHeading}
          completedCopy={completedCopy}
          thankYouCopy={thankYouCopy}
          romero={romero}
          upNext={upNext}
          lgca={lgca}
        />
      </m.div>
    </div>
  );
};

Action.defaultProps = {};

export default Action;
